<template>
	<div class="page-error h-screen flex w-full">
		<div class="vx-col flex justify-center flex-col w-1/2 md:w-1/1 mx-auto text-left align-start error-wrapper">
			<img src="@/assets/images/logo/koblatil.png" alt="graphic-404" class="mb-4 w-1/2 md:w-1/1" />
			<div class="divider-line w-1/2 md:w-full"></div>
			<div class="sm:mx-0 mx-4 mb-4 sm:mb-16 d-theme-text-inverse text-black">
				<div class="title-error">Error 404 - {{ $t('Page not found') }}</div>
				<span>{{ $t('Can we assist you to return to your') }}</span> <a @click="$router.go(-1)">{{ $t('previous page') }}</a>?
			</div>
		</div>
	</div>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
  created() {
    console.log(this.$i18n.locale, 'kjahgsdjahsgd');
    this.$store.dispatch('setLocale', 'en');
  },
  destroyed() {
  },
};
</script>
